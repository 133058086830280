import React, { useEffect, useState } from 'react';
import { SafeAreaView, Alert, StyleSheet, View, Text, ScrollView, Image, TouchableOpacity, Modal, Pressable } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { Linking, ActivityIndicator } from 'react-native';
import { differenceInCalendarDays } from 'date-fns';


const App = ({ navigation }) => {
  const [userData, setUserData] = useState({});
  const [collabs, setCollabs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showTutorial, setShowTutorial] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const [alertCollabs, setAlertCollabs] = useState([]); // Collabs in the alert timeframe
  const [showAlertSymbol, setShowAlertSymbol] = useState(false); // Show alert icon
  const [showCollabModal, setShowCollabModal] = useState(false); // Modal visibility
  const [currentCollab, setCurrentCollab] = useState(null); // Collab in the modal
  
  
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [venueCode, setVenueCode] = useState('');

  const tutorialSteps = [
    {
      icon: null,
      message: (
        <span>
          <strong>Welcome to Collabbay</strong> ✨ The platform where you exchange in-venue offers for influencer visits and content.
        </span>
      ),
    },
    {
      icon: 'add',
      message: (
        <span>
          Firstly <strong>create your offer</strong> by clicking the + icon.
        </span>
      ),
    },
    {
      icon: 'laptop',
      message: (
        <span>
          You'll be notified on each influencer booking request for your offer(s), and be able to <strong>analyse each creator's metrics</strong>.
        </span>
      ),
    },
    {
      icon: 'calendar',
      message: (
        <span>
          Seamlessly track all <strong>accepted upcoming influencer visits</strong> on your calendar. Whether you want to approve a single visit
          per month, or focus on a high volume of content, manage it all here.
        </span>
      ),
    },
    {
      icon: null,
      message: 'All influencer visits are tracked and logged on-platform. Choose how influencers will redeem your offers when they visit:',
      options: [
        {
          header: 'Code',
          subheader: (
            <span>
              Give the influencer a <strong>2-digit code</strong> when they visit
            </span>
          ),
          value: 'VenueCode',
          icon: 'key-outline',
        },
        {
          header: 'Scan (more secure)',
          subheader: (
            <span>
              Scan the influencer's <strong>QR code</strong> when they visit
            </span>
          ),
          value: 'QR',
          icon: 'qr-code-outline',
        },
        {
          header: '',
          subheader: 'Not sure yet',
          value: 'both',
          icon: null,
          small: true,
        },
      ],
    },
    {
      icon: null,
      message: (
        <span>
          <strong>Welcome.</strong> You'll need to subscribe to access collabbay.
        </span>
      ),
    },
  ];
  


  const [selectedOption, setSelectedOption] = useState(''); // Track selected option
  const [isUpdating, setIsUpdating] = useState(false); // Track API call status
  
  const handleOptionSelect = (value) => {
    setSelectedOption(value);
  
    // Call the API to update the database
    fetch('https://collabbayadmin.com/APIControllers/SelectVenueRedeemChoice.php', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        UserID: userData.UserID, // Ensure UserID is available
        Choice: value,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.Status === 'Success') {
          console.log('VenueRedeemSystem updated successfully');
        } else {
          console.error('Error updating VenueRedeemSystem:', data.Message);
        }
      })
      .catch((error) => console.error('API Error:', error));
  };
  

  useEffect(() => {
    fetchUserData();
    fetchCollabData();
  }, []);


  const fetchUserData = () => {
    fetch('https://collabbayadmin.com/APIControllers/GetSessionData.php')
      .then((response) => response.json())
      .then((data) => {
        setUserData(data);
        if (data.HasSeenTutorial === 0) {
          setShowTutorial(true);
        }
        if (data.VenueCode) {
          setVenueCode(data.VenueCode); // Update venueCode state
        }
      })
      .catch((error) => {
        console.error('Error fetching user data: ', error);
      });
  };


  const renderVenueCodeCard = () => {
    if (!venueCode) {
      console.warn('VenueCode is not available or empty.');
      return null;
    }
  
    return (
      <TouchableOpacity
        onPress={() => Linking.openURL('https://collabbayadmin.com/Visits')}
        style={[styles.venueCodeCard, { marginLeft: 25 }]} // Added marginRight directly
      >
        <Text style={styles.venueCodeText}>
          Your venue code is <Text style={styles.boldText}>{venueCode}</Text>
        </Text>
        <Ionicons name="help-circle-outline" size={20} color="black" style={styles.icon} />
    
        
      </TouchableOpacity>
    );
  };
  
  
  


  const fetchCurrentCollabData = async () => {
    try {
      const response = await fetch('https://collabbayadmin.com/APIControllers/GetCollabCalendarCurrent.php');
      const data = await response.json();
  
      const now = new Date();
      const oneHourBefore = new Date(now.getTime() - 60 * 60 * 1000);
      const fiveHoursAfter = new Date(now.getTime() + 5 * 60 * 60 * 1000);
  
      // Filter collabs that are "Accepted" and fall within the timeframe
      const filteredCollabs = data.filter((collab) => {
        const collabTime = new Date(collab.CreditAvailableStart);
        return (
          collab.Status === 'Accepted' &&
          collabTime >= oneHourBefore &&
          collabTime <= fiveHoursAfter
        );
      });
  
      setAlertCollabs(filteredCollabs); // Update state with filtered collabs
      setShowAlertSymbol(filteredCollabs.length > 0); // Show alert if there are valid collabs
    } catch (error) {
      console.error('Error fetching current collab data:', error);
    }
  };
  


  useEffect(() => {
    fetchUserData(); // Fetch user data
    fetchCollabData(); // Fetch general collab data
    fetchCurrentCollabData(); // Fetch current collabs
  }, []);

  const handleAlertPress = () => {
    if (alertCollabs.length > 0) {
      setCurrentCollab(alertCollabs[0]); // Set the first collab
      setShowCollabModal(true); // Open the modal
    }
  };
  

  const handleCollabNavigate = () => {
    setShowCollabModal(false); // Close the modal
    navigation.navigate('SingleCollabMetrics', {
      CollabID: currentCollab.CollabID,
    });
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case 'Expired':
        return { backgroundColor: 'grey', padding: 4, borderRadius: 10 };
      case 'Redeemed':
        return { backgroundColor: 'darkgreen', padding: 4, borderRadius: 10 };
      case 'Upcoming':
        return { backgroundColor: 'lightgreen', padding: 4, borderRadius: 10 };
      case 'Pending':
        return { backgroundColor: 'yellow', padding: 4, borderRadius: 10 };
      case 'Cancelled':
        return { backgroundColor: 'red', padding: 4, borderRadius: 10 };
      default:
        return { backgroundColor: 'transparent', padding: 4, borderRadius: 10 };
    }
  };
  
  const getStatusIcon = (status) => {
    switch (status) {
      case 'Expired':
        return <Ionicons name="time" size={12} color="black" />;
      case 'Redeemed':
        return <Ionicons name="checkmark-circle" size={12} color="black" />;
      case 'Upcoming':
        return <Ionicons name="calendar" size={12} color="black" />;
      case 'Pending':
        return <Ionicons name="alert-circle" size={12} color="black" />;
      case 'Cancelled':
        return <Ionicons name="close-circle" size={12} color="blackwhite" />;
      default:
        return null;
    }
  };
  
  const getCollabStatus = (collab) => {
    // Check if the status is "Cancelled" first
    if (collab.Status === 'Cancelled') {
        return 'Cancelled';
    }

    const today = new Date();
    const creditAvailableDate = new Date(collab.CreditAvailableStart);

    // Check if the credit availability date is in the past
    if (creditAvailableDate < today) {
        if (collab.Redeemed) {
            return 'Redeemed';
        } else {
            return 'Expired';
        }
    } else {
        // Check for other statuses
        if (collab.Status === 'Accepted') {
            return 'Upcoming';
        } else if (collab.Status === 'Pending') {
            return 'Pending';
        }
    }

    // Return null if no status matches
    return null;
};


  const checkUserAuthentication = async () => {
    try {
        const response = await fetch('https://collabbayadmin.com/APIControllers/UserSubscriptionAuth.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.json();

        // Check if the session username is set and user is authenticated
        if (data.username) {
            setIsAuthenticated(data.authenticated);
        } else {
            setIsAuthenticated(false);
        }

        if (!data.authenticated) {
            setErrorMessage('Subscribe to access collabbay features');
        } else {
            setErrorMessage('');
        }
    } catch (error) {
        console.error('Error checking authentication:', error);
        setErrorMessage('An error occurred while checking authentication.');
    }
};


useEffect(() => {
  checkUserAuthentication();
}, []);


   // Function to handle the link press
   const handleSubscribe = () => {
    Linking.openURL('https://collabbayadmin.com/subscribe');
};

  const fetchCollabData = () => {
    fetch('https://collabbayadmin.com/APIControllers/GetCollabCalendar.php', {
      credentials: 'include'
    })
      .then(response => response.json())
      .then(data => {
        if (data.message) {
          setCollabs([]);
        } else {
          setCollabs(data);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  };

  const handleRedoTutorial = () => {
    fetch('https://collabbayadmin.com/APIControllers/RedoTutorial.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data.Status === 'Success') {
          console.log('Tutorial reset successfully.');
          // Show the tutorial again and reset to the first step
          setShowTutorial(true);
          setCurrentStep(0);
          // Reload the page or refresh the data
          navigation.reset({
            index: 0,
            routes: [{ name: 'BusinessHomepage' }], // or your main screen name
          });
        } else {
          console.error('Error resetting tutorial status: ', data.Message);
        }
      })
      .catch(error => {
        console.error('Error resetting tutorial status: ', error);
      });
  };
  
  

  const renderTutorialButton = () => (
    <TouchableOpacity style={styles.redoButton} onPress={handleRedoTutorial}>
      <Ionicons name="book" size={20} color="black" />
    </TouchableOpacity>
  );
  
  
  const handleNextStep = () => {
    if (currentStep === 0 && selectedOption) {
      // Update database if it's the options step
      setIsUpdating(true); // Start updating
      fetch('https://collabbayadmin.com/APIControllers/SelectVenueRedeemChoice.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          Choice: selectedOption, // Send the selected option
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setIsUpdating(false); // Stop updating
          if (data.Status === 'Success') {
            console.log('VenueRedeemSystem updated successfully');
          } else {
            console.error('Error updating VenueRedeemSystem:', data.Message);
          }
          moveToNextStep(); // Move to the next step after updating
        })
        .catch((error) => {
          setIsUpdating(false); // Stop updating
          console.error('API Error:', error);
          moveToNextStep(); // Move to the next step even if there's an error
        });
    } else {
      moveToNextStep(); // Just move to the next step if no API call is needed
    }
  };
  
  // Function to move to the next step
  const moveToNextStep = () => {
    if (currentStep < tutorialSteps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      updateHasSeenTutorial(); // Mark tutorial as seen
      setShowTutorial(false);
    }
  };
  
  
  

  const updateHasSeenTutorial = () => {
    fetch('https://collabbayadmin.com/APIControllers/UpdateHasSeenTutorial.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        UserID: userData.UserID,
        HasSeenTutorial: 1,
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.Status !== 'Success') {
          console.error('Error updating tutorial status: ', data.Message);
        }
      })
      .catch(error => {
        console.error('Error updating tutorial status: ', error);
      });
  };


  const renderTutorial = () => {
    if (!showTutorial || currentStep >= tutorialSteps.length) {
      return null;
    }
  
    const step = tutorialSteps[currentStep];
  
    return (
      <Modal transparent visible={showTutorial} animationType="slide">
        <View style={styles.modalOverlay}>
          <View style={styles.tutorialCard}>
            <Text style={styles.tutorialText}>{step.message}</Text>
  
            {step.options && (
              <View>
                {step.options.map((option, index) => (
                  <TouchableOpacity
                    key={index}
                    style={[
                      styles.optionButton,
                      selectedOption === option.value && styles.optionButtonSelected,
                      option.small && styles.optionButtonSmall,
                    ]}
                    onPress={() => handleOptionSelect(option.value)}
                  >
                    {option.icon && (
                      <Ionicons
                        name={option.icon}
                        size={24}
                        color="black"
                        style={styles.optionIcon}
                      />
                    )}
                    <View>
                      <Text style={styles.optionHeader}>{option.header}</Text>
                      {option.subheader && (
                        <Text style={styles.optionSubheader}>
                          {option.subheader}
                        </Text>
                      )}
                    </View>
                  </TouchableOpacity>
                ))}
              </View>
            )}
  
            <Pressable
              style={[styles.nextButton, !selectedOption && { opacity: 0.5 }]}
              onPress={handleNextStep}
              disabled={isUpdating || (step.options && !selectedOption)} // Disable if updating or no option selected
            >
              <Text style={styles.buttonText}>
                {isUpdating ? 'Updating...' : currentStep < tutorialSteps.length - 1 ? 'Next' : 'Finish'}
              </Text>
            </Pressable>
          </View>
        </View>
      </Modal>
    );
  };
  

  const handleQRCodeScan = () => {
    Linking.openURL('https://collabbayadmin.com/QR.html');
  };

  const handleCardPress = (collabID) => {
    navigation.navigate('SingleCollabMetrics', { CollabID: collabID });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const hours = date.getHours() % 12 || 12;
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
  
    const formattedDate = `${day}${getOrdinalSuffix(day)} ${month} ${hours}:${minutes}${ampm}`;
  
    // Calculate days difference
    const today = new Date();
    const diffDays = differenceInCalendarDays(date, today);
  
    const dayCountText =
      diffDays === 0
        ? 'today'
        : diffDays > 0
        ? `in ${diffDays} day${diffDays > 1 ? 's' : ''}`
        : `${Math.abs(diffDays)} day${Math.abs(diffDays) > 1 ? 's' : ''} ago`;
  
    return `${formattedDate} (${dayCountText})`;
  };
  

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };
  

  if (loading) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: '#fff' }}>
        <ActivityIndicator size="large" color="black" />
      </View>
    );
  }

  return (
<SafeAreaView style={[styles.container, { width: '100%', maxWidth: 600, alignSelf: 'center', backgroundColor: '#fff', flex: 1 }]}>





<View style={styles.container}>

{userData.Username ? (
  isAuthenticated ? (
    null // Show nothing if authenticated
  ) : (
    errorMessage && (
      !showTutorial ? (
      <Modal animationType="fade" transparent={true}>
  <View style={styles.modalOverlay}>
    <View style={styles.subscriptionModal}>
      <Ionicons name="lock-closed" size={50} color="black" style={styles.subscriptionIcon} />
      <Text style={styles.subscriptionTitle}>Subscribe to Collabbay</Text>
      <Text style={styles.subscriptionMessage}>
        Unlock exclusive features to manage your influencer collaborations and grow your brand.
      </Text>
      <TouchableOpacity style={styles.subscribeButton} onPress={handleSubscribe}>
        <Text style={styles.subscribeButtonText}>Subscribe Now</Text>
      </TouchableOpacity>
      {/* Close Button */}
      <TouchableOpacity style={styles.closeButton} onPress={() => setErrorMessage('')}>
        <Text style={styles.closeButtonText}>Close</Text>
      </TouchableOpacity>
    </View>
  </View>
</Modal>

      ) : (
        <TouchableOpacity onPress={handleSubscribe} style={styles.messageContainer}>
          <Text style={styles.messageText}>{errorMessage}</Text>
          <Ionicons name="arrow-forward" size={50} color="black" />
        </TouchableOpacity>
      )
    )
  )
) : null}



        </View>

  {renderTutorialButton()}
  
      <View contentContainerStyle={styles.contentContainer}>
        <View>

        <View style={styles.header}>
  <View style={styles.headerRow}>
    <Image
      style={styles.smallRoundedLogo}
      source={{ uri: 'https://collabbayadmin.com/CollabbayLogoDark.png' }}
    />
    <Text style={styles.headerText}>Hi 👋, {userData.Username ? ` ${userData.Username}` : 'log in to collab'}</Text>
  </View>
</View>
<View style={styles.scrollableContainer}>
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        style={styles.cardScrollContainer}
      >
        <View style={styles.videoCard}>
          <video autoPlay loop muted playsInline style={styles.videoBackground}>
            <source src="https://collabbayadmin.com/Admin%20Web%20Intro%20%20Collabbay%20App%20(1).mp4" type="video/mp4" />
          </video>
        </View>

        <View style={styles.videoCard}>
        <Text style={styles.cardTitle}>
  {new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', timeZone: 'GMT', hour12: true })}
  <Text style={{ fontWeight: 'bold', color: '#FDF05D' }}>.</Text>
</Text>
          <TouchableOpacity onPress={() => navigation.navigate('CreateSubmissionPage')}>
            <Text style={styles.cardSubTitle}></Text>
          </TouchableOpacity>
        </View>

        <View style={styles.videoCard}>
          <video autoPlay loop muted playsInline style={styles.videoBackground}>
            <source src="https://collabbay.com/Influencer%20App%20Video%20-%20II.mp4" type="video/mp4" />
          </video>
        </View>

        <View style={styles.videoCard}>
          <Text style={styles.cardTitle}>
            Go #viral<Text style={{ fontWeight: 'bold', color: '#FDF05D' }}>.</Text>
          </Text>
          <Text style={styles.cardSubTitle}>
  
          </Text>
        </View>
      </ScrollView>
    </View>
    {renderVenueCodeCard()}
          <View style={styles.bottomBackground}>
            {userData.Username ? (
              <View style={styles.header}>
                {userData.Username ? `` : ''}
  
              </View>

              
            ) : (
              <View style={styles.header}>
                <TouchableOpacity onPress={() => navigation.navigate('LogInPage')}>
                <Text
    style={{
        fontSize: 12,
        fontWeight: 'bold',
        color: '#000',
        backgroundColor: '#fff', // White background
        padding: 7, // Padding around the text
        borderRadius: 12, // Rounded borders
        borderWidth: 1, // Border width
        borderColor: '#fdf05d', // Border color
        textAlign: 'center', // Center the text
        overflow: 'hidden', // Ensures rounded borders
        display: 'flex', // Enables flex layout
        flexDirection: 'row', // Aligns icon and text horizontally
        alignItems: 'center', // Vertically centers the items
        marginTop:20,
    }}
>
    <Ionicons name="log-in-outline" size={20} color="#000" style={{ marginRight: 8 }} />
    Log in or create an account
</Text>
                </TouchableOpacity>
              </View>
            )}

            



            {/* Dashboard Section */}
            <View style={styles.section}>
              <View style={styles.cardRow}>
                <TouchableOpacity style={styles.card} onPress={() => navigation.navigate('BrandListingsDashboard')}>
                  <Ionicons name="laptop" size={35} color="white" />
                  <Text style={styles.cardTitle}>My Offers</Text>
                  <Text style={styles.cardSubText}>Go to Dashboard</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.card} onPress={handleQRCodeScan}>
                  <Ionicons name="scan" size={35} color="white" />
                  <Text style={styles.cardTitle}>QR Scan</Text>
                  <Text style={styles.cardSubText}>Open Scanner</Text>
                </TouchableOpacity>
              </View>
            </View>



     {showAlertSymbol && (
  <TouchableOpacity onPress={handleAlertPress} style={{ flexDirection: 'row', alignItems: 'center' }}>
    <Ionicons name="alert-circle" size={50} color="fdf05d" />
    <Text
  style={{
    marginLeft: 8,
    fontSize: 25,
    color: 'black',
    fontWeight: 'bold', // Fixed typo here
    backgroundColor: 'white',
    padding: 10,
    borderRadius: 8, // Rounded border
  }}
>Influencer at your venue now? Click here.
</Text>
    
  </TouchableOpacity>
)}




{currentCollab && (
  <Modal
    visible={showCollabModal}
    transparent
    animationType="slide"
    onRequestClose={() => setShowCollabModal(false)}
  >
    <View style={styles.modalOverlay}>
      <View style={styles.modalContent}>
        {/* Close Button */}
        <TouchableOpacity
          style={styles.closeButton}
          onPress={() => setShowCollabModal(false)}
        >
          <Ionicons name="close" size={24} color="black" />
        </TouchableOpacity>

        {/* Modal Title */}
        <Text style={styles.modalTitle}>Are you collabing now?</Text>

        {/* Modal Message */}
        <Text style={styles.modalMessage}>
          You have an upcoming collab with{' '}
          <Text style={{ fontWeight: 'bold' }}>{currentCollab.BrandName}</Text>.
        </Text>

        {/* Collab Instructions */}
        <View style={styles.instructionList}>
          <Text style={styles.instructionHeader}>

          </Text>


          <View>
  <Text style={[styles.instructionItem, { marginBottom: 10 }]}>
    • 1. Welcome the creator. 👋
  </Text>
  <Text style={[styles.instructionItem, { marginBottom: 10 }]}>
    • 2. Provide the agreed offer or service. 🎉
  </Text>
  <Text style={[styles.instructionItem, { marginBottom: 10 }]}>
    • 3. The creator will begin to prepare their content. 🔗
  </Text>
  <Text style={[styles.instructionItem, { marginBottom: 10 }]}>
    • 4. Scan the creator's QR code before they leave to confirm their visit. 🗺
  </Text>
  <Text style={[styles.instructionItem, { marginBottom: 10 }]}>
    • 5. Await the creator's content upload to Collabbay as per the agreed timeline. 📤
  </Text>
</View>



        </View>

        {/* Navigate to Collab Button */}
        <TouchableOpacity
          style={styles.modalButton}
          onPress={handleCollabNavigate}
        >
          <Text style={styles.modalButtonText}>Go to {currentCollab.Promoter} Collab</Text>
        </TouchableOpacity>
      </View>
    </View>
  </Modal>
)}


            {/* Upcoming Collabs Section */}

            {userData.Username && (
  <>

<View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: '3%', marginVertical: 10 }}>
  <Text style={{ fontSize: 24, fontWeight: 'bold', textAlign: 'left', flex: 1 }}>Collab Calendar</Text>
  <TouchableOpacity
    style={{
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: '#fdf05d',
      borderRadius: 10,
      paddingVertical: 5,
      paddingHorizontal: 10,
    }}
    onPress={() => navigation.navigate('BrandListingsDashboard')}
  >
    <View
      style={{
        backgroundColor: 'white',
        borderRadius: 10,
        paddingVertical: 5,
        paddingHorizontal: 10,
        marginRight: 5,
      }}
    >
      <Text style={{ fontSize: 14, fontWeight: 'bold', color: 'black' }}>Go to all collabs</Text>
    </View>
    <Ionicons name="arrow-forward" size={20} color="black" />
  </TouchableOpacity>
</View>


<View style={styles.section}>
  {loading ? (
    <ActivityIndicator size="large" color="black" /> // Show a loading spinner while data is being fetched
  ) : (
    collabs.length === 0 ? (

      <View style={{ backgroundColor: 'white', borderRadius: 15, padding: 10, marginTop: 10, shadowColor: '#000', shadowOpacity: 0.1, shadowRadius: 5, shadowOffset: { width: 0, height: 2 }, elevation: 2 }}>
  <Text style={[styles.noCollabText, { textAlign: 'left' }]}>
    No upcoming collabs
  </Text>
</View>


    ) : (
      <>
        {collabs.length > 100 && (
          <ActivityIndicator size="large" color="#0000ff" style={{ marginVertical: 10 }} />
        )}
        {collabs.map((collab, index) => {
          const status = getCollabStatus(collab); // Get dynamic status

          return (
            <TouchableOpacity
              key={index}
              style={styles.collabCard}
              onPress={() => handleCardPress(collab.CollabID)}
            >
              <Ionicons name="calendar" size={24} color="black" style={styles.icon} />
              <Text style={styles.collabCardTitle}>{formatDate(collab.CreditAvailableStart)}</Text>

              <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Text style={styles.collabCardSubTitle}></Text>
                <View style={[getStatusStyle(status), { flexDirection: 'row', alignItems: 'center' }]}>
                  {getStatusIcon(status)}
                  <Text style={{ color: 'black', marginLeft: 4 }}>{status}</Text>
                </View>
              </View>

              <Text style={{ marginTop: 5, marginBottom: 5 }}>
                Offer:{" "}
                <Text
                  style={{
                    backgroundColor: '#f3f3f3',
                    padding: 5,
                    borderRadius: 15,
                    alignSelf: 'flex-start',
                  }}
                >
                  {collab.NonCreditOffer.length > 40
                    ? collab.NonCreditOffer.substring(0, 40) + "..."
                    : collab.NonCreditOffer}
                </Text>
              </Text>

              <Text style={styles.collabCardSubTitle}>for {collab.Promoter}</Text>
            </TouchableOpacity>
          );
        })}
      </>
    )
  )}
</View>
  </>
)}

  


        {/* <TouchableOpacity onPress={() => Linking.openURL('https://collabbay.com/PrivacyPolicy.html')}>
          <Text style={styles.linkText}> By using collabbay, you agree to our Privacy Policy and Terms of Use. </Text>
        </TouchableOpacity> */}

        </View>
        </View>
      </View>

      <View style={styles.footer}>
        <TouchableOpacity style={styles.footerIcon} onPress={handleQRCodeScan}>
          <Ionicons name="scan" size={24} color="black" />
        </TouchableOpacity>

        {userData.Username ? (
          <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('BusinessAccountPage')}>
            <Ionicons name="person" size={24} color="black" />
          </TouchableOpacity>
        ) : (
          <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('LogInPage')}>
            <Ionicons name="person" size={24} color="black" />
          </TouchableOpacity>
        )}

        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('BusinessHomepage')}>
          <Ionicons name="home" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('CollabCalendar')}>
          <Ionicons name="calendar" size={24} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={styles.footerIcon} onPress={() => navigation.navigate('CreateBrandListingChoice')}>
          <Ionicons name="add" size={24} color="black" />
        </TouchableOpacity>
      </View>
      
      {renderTutorial()}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    maxwidth: 400,
    
  },
  contentContainer: {
    paddingHorizontal: 10,
    alignItems: 'center',
    alignSelf: 'center',
    paddingTop: '5',
  },
  section: {
    marginBottom: 20,
  },
  loginText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'black',
  },
  bannerContainer: {
    marginBottom: 20,
    borderRadius: 10,
    minWidth: 350,
    marginTop: 30,
    paddingTop: 30,
    backgroundColor: 'black',
    width: '100%',
    height: 150,
  },
  bannerImage: {
    width: '100%',
    height: '100%',
    borderRadius: 10,
  },
  cardRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    marginTop: 15,
  },
  card: {
    width: '48%',
    height: 150,
    backgroundColor: 'black',
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    position: 'relative',
  },
  cardTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 5,
    color: 'white',
    textAlign: 'center',
  },
  cardSubText: {
    fontSize: 12,
    textAlign: 'center',
    color: 'white',
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    backgroundColor: '#ffff',
    position: 'sticky',
    bottom: 0,
    left: 0,
    right: 0,
    paddingVertical: 10,
  },
  footerIcon: {
    alignItems: 'center',
  },
  cornerTopLeft: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 10,
    height: 10,
    borderTopLeftRadius: 10,
  },
  cornerTopRight: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 10,
    height: 10,
    borderTopRightRadius: 10,
  },
  cornerBottomLeft: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: 10,
    height: 10,
    borderBottomLeftRadius: 10,
  },
  cornerBottomRight: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: 10,
    height: 10,
    borderBottomRightRadius: 10,
  },
  bottomBackground: {
    backgroundColor: '#FDF05D',
    borderRadius: 30,
    paddingHorizontal: 10,
    paddingHorizontal: 10,
    width: '92.5%', // Or set any specific maxWidth like '80%' depending on your design
    alignSelf: 'center', // Centers the container horizontally
marginBottom: '24%',  

  },
  linkText: {
    fontSize: 9,
    color: 'black',
    textDecorationLine: 'underline',
    textAlign: 'center',
    marginTop: 20,
    borderRadius: '10',
    padding: '5',
    marginBottom: 3,
  },
  sectionHeader: {
    fontSize: 24,
    fontWeight: 'bold',
    marginVertical: '1%',
    alignSelf: 'center',
  },

  calendarSectionHeader: {
    fontSize: 24,
    fontWeight: 'bold',
    marginVertical: '1%',
    alignSelf: 'left',
  },
  
  collabCard: {
    width: '94%',
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 10,
    marginVertical: 5,
    alignSelf: 'center',
  },
  collabCardTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 5,
    color: 'black',
  },
  collabCardSubTitle: {
    fontSize: 12,
    color: 'black',
  },
  noCollabText: {
    fontSize: 9,
    color: 'black',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  modalOverlay: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tutorialCard: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    alignItems: 'center',
    width: '80%',
  },
  iconLarge: {
    marginBottom: 15,
  },
  tutorialText: {
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 10,
  },
  nextButton: {
    marginTop: 10,
    padding: 10,
    backgroundColor: '#000',
    borderRadius: 5,
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
  },
  redoButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    backgroundColor: '#FDF05D', 
    padding: 6,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10, // Ensure it's above other elements
  },
  messageContainer: {
    padding: 10,                     // Maintain padding for spacing
    backgroundColor: '#f3f3f0',        // Set background to blac
    marginBottom: 10,
    marginTop: 10,          // Optional: White border for contrast             // Border width for rounded effect
    borderRadius: 10,                // Rounded corners
    width: '70%',            
    margintop: 10,        // Set width to 50% of screen
    alignSelf: 'center',             // Center horizontally
    justifyContent: 'center',        // Center vertically within flex container
    alignItems: 'center',            // Center text and content inside
    marginVertical: 'auto',          // Center vertically on the screen
  },
  messageText: {
    color: 'black',                   // Sets text color to white
    fontWeight: 'bold', // Underlines the text
    textAlign: 'center',              // Centers the text
    fontSize: 10,                     // Adjust font size as needed
    marginRight: 5,                   // Adds space between text and icon  
  },
welcomeText: {
    fontSize: 18,
    color: 'darkgrey',
},
headerRow: {
  flexDirection: 'row', // Horizontal alignment
  alignItems: 'center', // Vertically center items
  justifyContent: 'space-between', // Push items to sides
  width: '92.5%', // Full width of container
  paddingHorizontal: 1, // Add padding for spacing
  marginBottom: 20, // Optional: Adjust vertical spacing
  alignSelf: 'center', // Centers the container horizontally
marginTop: 10,
},

smallRoundedLogo: {
  width: 40, // Set width for small size
  height: 40, // Set height for small size
  borderRadius: 20, // Make it fully rounded
  backgroundColor: 'black', // Black background
  marginRight: 10,
  paddingLeft: 10, // Space between logo and text
},

headerText: {
  fontSize: 16, // Adjust font size
  fontWeight: 'bold', // Make it bold
  color: 'black', // Set text color
  flex: 1, // Allow text to take remaining space
  textAlign: 'left', // Align text to the right
  paddingLeft: 7,
},
cardScrollContainer: {
  marginTop: 20,
  paddingLeft: 7,
  marginBottom: 20,
  width: '100%', // Full width of the screen
  flexDirection: 'row', // Horizontal scrolling
  overflow: 'hidden', // Prevent content spilling out

},
videoCard: {
  width: 130,  
  height: 190, // Set the height of each card
  backgroundColor: 'black', // Fallback color for the card in case video fails
  marginRight: 15, // Space between the cards
  borderRadius: 10, // Rounded corners for the card
  justifyContent: 'center', // Center the video inside the card
  alignItems: 'center', // Center the video inside the card
  position: 'relative', // Ensure the video covers the card properly
},
videoBackground: {
  position: 'absolute', // Ensures the video is positioned inside the card
  top: 0,
  left: 0,
  width: '100%', // Make the video fill the card width
  height: '100%', // Make the video fill the card height
  borderRadius: 10, // Apply rounded corners to the video as wellS
  zIndex: 0, // Ensure the video stays behind other content
},
scrollableContainer: {
  width: '92.5%', // Or set any specific maxWidth like '80%' depending on your design
  alignSelf: 'center', // Centers the container horizontally
},
modalOverlay: {
  flex: 1,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  justifyContent: 'center',
  alignItems: 'center',
},
modalContent: {
  backgroundColor: '#fff',
  padding: 20,
  borderRadius: 10,
  width: '80%',
  alignItems: 'center',
},
modalTitle: { fontSize: 18, fontWeight: 'bold', marginBottom: 10 },
modalMessage: { fontSize: 16, textAlign: 'center', marginBottom: 20 },
instructionList: { marginBottom: 20 },
instructionItem: { marginBottom: 10, fontSize: 14 },
modalButton: { backgroundColor: '#fdf05d', padding: 10, borderRadius: 5 },
modalButtonText: { fontWeight: 'bold', textAlign: 'center' },
alertContainer: { flexDirection: 'row', alignItems: 'center', marginVertical: 20 },
alertText: { marginLeft: 10, fontSize: 16, fontWeight: 'bold' },
modalOverlay: {
  flex: 1,
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
  justifyContent: 'center',
  alignItems: 'center',
},
subscriptionModal: {
  backgroundColor: 'white',
  padding: 20,
  borderRadius: 10,
  alignItems: 'center',
  justifyContent: 'center',
  width: '85%', // Adjust width as per your design
},
subscriptionIcon: {
  marginBottom: 15,
},
subscriptionTitle: {
  fontSize: 22,
  fontWeight: 'bold',
  marginBottom: 10,
  textAlign: 'center',
  color: 'black',
},
subscriptionMessage: {
  fontSize: 16,
  color: 'grey',
  textAlign: 'center',
  marginBottom: 20,
},
subscribeButton: {
  backgroundColor: '#FDF05D',
  paddingVertical: 15,
  paddingHorizontal: 20,
  borderRadius: 10,
  width: '100%',
  alignItems: 'center',
  shadowColor: '#000',
  shadowOffset: { width: 0, height: 2 },
  shadowOpacity: 0.2,
  shadowRadius: 5,
},
subscribeButtonText: {
  fontSize: 16,
  fontWeight: 'bold',
  color: 'black',
  textAlign: 'center',
},
modalOverlay: {
  flex: 1,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  justifyContent: 'center',
  alignItems: 'center',
},
subscriptionModal: {
  backgroundColor: '#fff',
  padding: 20,
  borderRadius: 10,
  alignItems: 'center',
  width: '80%',
},
subscriptionIcon: {
  marginBottom: 20,
},
subscriptionTitle: {
  fontSize: 20,
  fontWeight: 'bold',
  marginBottom: 10,
  textAlign: 'center',
},
subscriptionMessage: {
  fontSize: 16,
  textAlign: 'center',
  marginBottom: 20,
},
subscribeButton: {
  backgroundColor: '#fdf05d',
  padding: 10,
  borderRadius: 5,
},
subscribeButtonText: {
  fontWeight: 'bold',
  textAlign: 'center',
},
  spinner: {
    marginVertical: 10, // Adds spacing above and below the spinner
    alignSelf: 'center', // Centers the spinner horizontally
  },
    spinner: {
    marginVertical: 10, // Adds spacing above and below the spinner
    alignSelf: 'center', // Centers the spinner horizontally
  },
  sectionHeader: {
  fontSize: 24,
  fontWeight: 'bold',
  marginBottom: 10,
},
spinner: {
  marginVertical: 10,
  alignSelf: 'center',
},
noCollabText: {
  fontSize: 16,
  color: 'black',
  textAlign: 'center',
  marginVertical: 10,
},
loadingContainer: {
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#fff', // Optional background color
},
optionButton: {
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: '#f3f3f3',
  padding: 10,
  marginVertical: 5,
  borderRadius: 5,
},
optionButtonSelected: {
  backgroundColor: '#fdf05d',
},
optionIcon: {
  marginRight: 10,
},
optionLabel: {
  fontSize: 16,
  fontWeight: 'bold',
  color: 'black',
},
venueCodeCard: {
 marginBottom: 15,
  backgroundColor: '#f3f3f3', // Card background colour
  paddingHorizontal: 12, // Horizontal padding
  paddingVertical: 8, // Vertical padding
  borderRadius: 17, // Rounded corners
  borderWidth: 1, // Border for definition
  width: '47%',
  marginLeft: '40',
  borderColor: '#f3f3f3', // Subtle border colour
  elevation: 2, // Shadow for depth
  flexDirection: 'row', // Align text and icon in a row
  alignItems: 'center', // Vertically centre-align content
  zIndex: 10, // Ensure it sits above other elements
},

venueCodeText: {
  fontSize: 18,
  color: '#000',
  fontWeight: '400',
},
boldText: {
  fontWeight: 'bold',
},
closeButton: {
  marginTop: 20,
  backgroundColor: 'black', // Black button
  paddingVertical: 10,
  paddingHorizontal: 20,
  borderRadius: 5,
  alignItems: 'center',
},
closeButtonText: {
  color: 'white', // White text
  fontWeight: 'bold',
  fontSize: 16,
},





});

export default App;
