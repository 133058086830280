import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, ActivityIndicator, ScrollView, TouchableOpacity, SafeAreaView } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { Calendar } from 'react-native-calendars';
import { format, differenceInCalendarDays } from 'date-fns';

const BrandCalendar = ({ route, navigation }) => {
  const [collabs, setCollabs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [markedDates, setMarkedDates] = useState({});

  useEffect(() => {
    fetch('https://collabbayadmin.com/APIControllers/GetCollabCalendar.php', {
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          setCollabs([]);
        } else {
          setCollabs(data);
          const dates = {};
          data.forEach((collab) => {
            const date = format(new Date(collab.CreditAvailableStart), 'yyyy-MM-dd');
            dates[date] = {
              marked: true,
              dotColor: '#000000',
              selected: true,
              selectedColor: '#FDF05D', 
              selectedTextColor: '#333',
            };
          });
          setMarkedDates(dates);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = () => {
    fetch('https://collabbayadmin.com/APIControllers/GetSessionData.php')
      .then((response) => response.json())
      .then((data) => {
        setUserData(data);
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
      });
  };


  
  const handleBackPress = () => {
    navigation.goBack();
  };

  const handleCardPress = (collabID) => {
    navigation.navigate('SingleCollabMetrics', { CollabID: collabID });
  };

  const handleScanIconPress = () => {
    navigation.navigate('QRCodeScannerPage');
  };

  const handlePersonIconPress = () => {
    navigation.navigate('UserRecapPage');
  };

  const getCollabStatus = (collab) => {
    if (collab.Status === 'Cancelled') {
      return 'Cancelled';
    }
  
    const today = new Date();
    const creditAvailableDate = new Date(collab.CreditAvailableStart);
  
    if (creditAvailableDate < today) {
      return collab.Redeemed ? 'Redeemed' : 'Expired';
    } else {
      return collab.Status === 'Accepted' ? 'Upcoming' : 'Pending';
    }
  };
  

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const suffix = day === 1 || day === 21 || day === 31 ? 'st' : day === 2 || day === 22 ? 'nd' : day === 3 || day === 23 ? 'rd' : 'th';
    const month = format(date, 'MMMM');
    return `${day}${suffix} ${month}`;
  };

  const calculateDaysDifference = (dateString) => {
    const today = new Date();
    const targetDate = new Date(dateString);
    const diffDays = differenceInCalendarDays(targetDate, today);
    return diffDays === 0 ? 'Today' : `in ${diffDays} day${diffDays > 1 ? 's' : ''}`;
  };

  if (loading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color="black" />
      </View>
    );
  }

  return (
    <SafeAreaView style={[styles.container, { width: '100%', maxWidth: 600, alignSelf: 'center', backgroundColor: '#fff', flex: 1 }]}>
    <View style={styles.container}>
      <View style={styles.backContainer}>
      <TouchableOpacity style={styles.backButton} onPress={handleBackPress}>
            <Ionicons name="arrow-back" size={28} color="black" />
          </TouchableOpacity>
          </View>

        <ScrollView contentContainerStyle={styles.scrollViewContent}>
        
          {/* Header */}
          <View style={styles.header}>
            <Text style={styles.headerTitle}>Collab Calendar</Text>
          </View>

          {/* Calendar Section */}
          <View style={styles.calendarWrapper}>
            <Calendar
              markedDates={markedDates}
              onDayPress={(day) => {
                const selectedCollabs = collabs.filter(
                  (collab) => format(new Date(collab.CreditAvailableStart), 'yyyy-MM-dd') === day.dateString
                );
                if (selectedCollabs.length > 0) {
                  handleCardPress(selectedCollabs[0].CollabID);
                }
              }}
              theme={{
                arrowColor: 'black',
                selectedDayBackgroundColor: '#FFD700',
                selectedDayTextColor: '#333',
                dotColor: '#00BFFF',
                textDayFontWeight: 'bold',
                textDayFontSize: 16,
                'stylesheet.day.basic': {
                  today: {
                    borderBottomColor: 'black',
                    borderBottomWidth: 2,
                    color: 'black', // Ensure the text color of the current date is black
                  },
                },
              }}
              style={styles.calendar}
              enableSwipeMonths={true}
            />
          </View>

          {/* CRM Section Placeholder */}
          <View style={styles.crmSection}>
            <Text style={styles.crmTitle}>Influencer Collab Visits</Text>
            <Text style={styles.crmSubtitle}>Upcoming creator visits...</Text>
          </View>

          {/* Scrollable Collab Cards */}
          {collabs.length === 0 ? (
  <Text style={styles.noResults}>No upcoming collabs</Text>
) : (
  collabs.map((collab, index) => {
    const collabStatus = getCollabStatus(collab); // Determine the dynamic status

    return (
      <TouchableOpacity
        key={index}
        style={styles.card}
        onPress={() => handleCardPress(collab.CollabID)}
      >
        {/* Status Badge */}
        <View style={[styles.statusBadge, styles[`${collabStatus.toLowerCase()}Status`]]}>
          <Text style={styles.statusText}>{collabStatus}</Text>
        </View>

        <View style={styles.cardContent}>
          <Ionicons name="calendar" size={24} color="#FFD700" style={styles.icon} />
          <View style={styles.cardText}>
            <Text style={styles.cardTitle}>{formatDate(collab.CreditAvailableStart)}</Text>
            <Text style={styles.cardSubTitle}>{calculateDaysDifference(collab.CreditAvailableStart)}</Text>
          </View>
        </View>

        <View style={styles.cardDetails}>
          <View style={styles.cardRow}>
            <Ionicons name="checkmark-circle" size={18} color="#000000" />
            <Text style={styles.cardSubTitle}>Status: {collab.Status}</Text>
          </View>
          <View style={styles.cardRow}>
            <Ionicons name="wallet" size={18} color="#000000" />
            <Text style={styles.cardSubTitle}>Offer: {collab.NonCreditOffer}</Text>
          </View>
          <View style={styles.cardRow}>
            <Ionicons name="briefcase" size={18} color="#000000" />
            <Text style={[styles.cardSubTitle, { fontWeight: 'bold' }]}>for {collab.Promoter}</Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  })
)}


        </ScrollView>
      </View>


    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
  },
  container: {
    flex: 1,
    backgroundColor: '#fff',
    padding: 20,
    paddingBottom: 80, // Adjust to provide space for the footer
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  header: {
    padding: 20,
    backgroundColor: '#FDF05D',
    borderRadius: 10,
    marginBottom: 20,
    alignItems: 'center',
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#333',
  },
  calendarWrapper: {
    marginBottom: 20,
    backgroundColor: '#fff',
    borderRadius: 10,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowRadius: 5,
    elevation: 5,
  },
  calendar: {
    width: '100%',
    paddingVertical: 10,
  },
  crmSection: {
    padding: 15,
    borderRadius: 10,
    backgroundColor: '#f5f5f5',
    marginBottom: 20,
  },
  crmTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#333',
    marginBottom: 5,
  },
  crmSubtitle: {
    fontSize: 14,
    color: '#666',
  },
  scrollViewContent: {
    flexGrow: 1,
  },
  noResults: {
    fontSize: 18,
    textAlign: 'center',
    marginTop: 20,
    color: '#555',
  },
  card: {
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 15,
    marginBottom: 15,
    borderColor: '#ddd',
    borderWidth: 1,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowRadius: 5,
    elevation: 5,
  },
  cardContent: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  cardText: {
    marginLeft: 10,
  },
  cardTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#333',
  },
  cardSubTitle: {
    fontSize: 14,
    color: '#555',
  },
  cardDetails: {
    marginTop: 10,
  },
  cardRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  icon: {
    marginRight: 10,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingVertical: 10,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#fff',
    borderTopWidth: 1,
    borderTopColor: '#ddd',
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowRadius: 10,
    elevation: 10,
  },
  footerIcon: {
    alignItems: 'center',
  },
  backContainer: {
    alignItems: 'center',
    marginBottom: 20,
    marginTop: 0,
    padding: 20,
  },
  backButton: {
    position: 'absolute',
    top: 5,
    left: 15,
    zIndex: 1,
  },
  statusBadge: {
    position: 'absolute',
    top: 10,
    right: 10,
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 5,
  },
  expiredStatus: {
    backgroundColor: 'grey',
    color: 'black',
  },
  redeemedStatus: {
    backgroundColor: 'green',
    color: 'white',
  },
  upcomingStatus: {
    backgroundColor: 'lightgreen',
    color: 'black',
  
  },
  pendingStatus: {
    backgroundColor: 'yellow',
    color: 'black',
  },
  cancelledStatus: {
    backgroundColor: 'red',
    color: 'white',
  },
  statusText: {
    fontWeight: 'bold',
    fontSize: 12,
    color: 'black',
  },
  
});

export default BrandCalendar;
