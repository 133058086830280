import React, { useEffect, useState } from 'react';
import { View, Text, ScrollView, StyleSheet, FlatList, TouchableOpacity, Image, Linking, Dimensions, Button, Alert, TextInput } from 'react-native';
import axios from 'axios';
import { Ionicons } from '@expo/vector-icons';
import { LineChart, PieChart } from 'react-native-chart-kit';

// Get screen width
const screenWidth = Dimensions.get('window').width;




export default function UserAnalyticsDashboard() {
  const [userList, setUserList] = useState([]);
  const [analyticsData, setAnalyticsData] = useState(null);
  const [kpiData, setKpiData] = useState(null);

  const totalOnboardedAndNewUsers = (onboardedUsers || 0) + (newUsers || 0);

  const [inputData, setInputData] = useState({});


  useEffect(() => {
    // Fetch data from the PHP API endpoint
    axios.get('https://collabbayadmin.com/APIControllers/collabbayAnalytics.php')
      .then((response) => {
        setAnalyticsData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  useEffect(() => {
    // Fetch user data
    axios.get('https://collabbayadmin.com/APIControllers/AdminDashboardUserList.php')
      .then((response) => {
        setUserList(response.data.data); // Assuming 'data' contains the user details
      })
      .catch((error) => {
        console.error('Error fetching user list:', error);
      });
  }, []);
  
  useEffect(() => {
    axios
      .get('https://collabbayadmin.com/APIControllers/collabbayAdminKPIData.php')
      .then((response) => {
        setKpiData(response.data.data); // Save the `data` field in the response
      })
      .catch((error) => {
        console.error('Error fetching KPI data:', error);
      });
  }, []);

  

  const sendBrandReminder = (brandName, collabID) => {
    axios
      .post("https://collabbayadmin.com/APIControllers/SendBrandReminder.php", {
        brandName,
        collabID,
      })
      .then(() => {
        alert("Success", "Brand reminder sent successfully!");
      })
      .catch((error) => {
        alert(
          "Error",
          error.response?.data?.message || "Failed to send brand reminder."
        );
      });
  };
  
  

  const handleOnboardAndAssignPublicFigure = (
    username,
    platformFollowers,
    audienceLocation,
    botsFakes
  ) => {
    axios
      .post("https://collabbayadmin.com/APIControllers/AdminUpdateUser.php", {
        username: username,
        updateType: "onboardAndPublicFigure",
        platformFollowers: platformFollowers || null,
        audienceLocation: audienceLocation || null,
        botsFakes: botsFakes || null,
      })
      .then(() => {
        alert("Success, user onboarded and updated successfully ", "User onboarded and updated successfully!");
      })
      .catch((error) => {
        console.error("Error updating user:", error);
        Alert.alert("Error", "Failed to onboard and update user.");
      });
  };
  

if (!analyticsData || !kpiData) {
  return (
    <View style={styles.container}>
      <Text>Loading data...</Text>
    </View>
  );
}





  // Extract metrics from data
  const {
    totalUsers,
    promoters,
    brands,
    onboardedUsers,
    newUsers,
    noProfileImageCount,
    cumulativeUsers,
    dailyUsers,
    totalCollabs,
    upcomingCollabs,
    pastCollabs,
    collabsByBrand,
    newUserList
  } = analyticsData;

  // Prepare data for the cumulative and daily user charts
  const cumulativeLabels = cumulativeUsers.map((item) => item.date);
  const cumulativeCounts = cumulativeUsers.map((item) => item.count);
  const dailyLabels = dailyUsers.map((item) => item.date);
  const dailyCounts = dailyUsers.map((item) => item.count);


  // Data for onboarded vs new users pie chart
  const pieChartData = [
    { name: 'Onboarded', count: onboardedUsers, color: '#FDF05D', legendFontColor: '#333', legendFontSize: 15 },
    { name: 'New', count: newUsers, color: '#000', legendFontColor: '#333', legendFontSize: 15 }
  ];

  return (
    <ScrollView style={styles.container}>


<TouchableOpacity
  style={{
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 10,
    marginTop: 5,
    marginBottom: 8,
    alignSelf: 'flex-start',
  }}
  onPress={() => Linking.openURL('https://collabbayadmin.com')}
>
  <Ionicons name="arrow-back" size={20} color="black" />
  <Text
    style={{
      fontSize: 10,
      color: 'white',
      marginLeft: 10,
      fontWeight: 'bold',
    }}
  >

  </Text>
</TouchableOpacity>

<TouchableOpacity
  style={{
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'black',
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 10,
    marginTop: 20,
    marginBottom: 7,
    alignSelf: 'flex-start',
  }}
  onPress={() => Linking.openURL('https://collabbayadmin.com/EMAILTOOL')}
>
  <Ionicons name="mail-outline" size={20} color="white" />
  <Text
    style={{
      fontSize: 10,
      color: 'white',
      marginLeft: 10,
      fontWeight: 'bold',
    }}
  >
    Open Email Tool
  </Text>
</TouchableOpacity>

<TouchableOpacity
  style={{
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'black',
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 10,
    marginTop: 20,
    marginBottom: 7,
    alignSelf: 'flex-start',
  }}
  onPress={() => Linking.openURL('https://collabbayadmin.com/SubscriptionPlans')}
>
<Ionicons name="cash-outline" size={20} color="white" />

  <Text
    style={{
      fontSize: 10,
      color: 'white',
      marginLeft: 10,
      fontWeight: 'bold',
    }}
  >
    Subscription Tracker
  </Text>
</TouchableOpacity>


<TouchableOpacity
  style={{
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'black',
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 10,
    marginTop: 20,
    marginBottom: 7,
    alignSelf: 'flex-start',
  }}
  onPress={() => Linking.openURL('https://collabbayadmin.com/AdminContentRAGTool')}
>
<Ionicons name="document-outline" size={20} color="white" />
  <Text
    style={{
      fontSize: 10,
      color: 'white',
      marginLeft: 10,
      fontWeight: 'bold',
    }}
  >
   Content Tracker
  </Text>
</TouchableOpacity>


<Text style={styles.title}>collabbay | Influence Dashboard</Text>

      {/* KPI Metrics Section */}
<View style={styles.metricsContainer}>



<View style={{ backgroundColor: '#FDF05D', borderRadius: 10, borderColor: '#ccc', borderWidth: 1, padding: 10 }}>
  <Text style={[styles.metric, { fontWeight: 'bold', marginBottom: 5 }]}>
    <Ionicons name="log-in-outline" size={16} color="#333" /> Logins Today: {kpiData?.logins?.logins_today || 0}
  </Text>
  <Text style={[styles.metric, { fontWeight: 'bold', marginBottom: 5 }]}>
    <Ionicons name="calendar-outline" size={16} color="#333" /> Logins (Last 7 Days): {kpiData?.logins?.logins_last_7_days || 0}
  </Text>
  <Text style={[styles.metric, { fontWeight: 'bold' }]}>
    <Ionicons name="calendar-sharp" size={16} color="#333" /> Logins (Last 30 Days): {kpiData?.logins?.logins_last_30_days || 0}
  </Text>
</View>




<View>
  <Text style={[styles.metric, { fontSize: 25, fontWeight: 'bold', marginBottom: 5, marginTop: 15 }]}>
    {kpiData?.total_creators || 0} Influencers
  </Text>
  <Text style={[styles.metric, { fontSize: 25, fontWeight: 'bold', marginBottom: 3 }]}>
    {kpiData?.total_venues || 0} Venues
  </Text>
  <Text style={[styles.metric, { fontSize: 12, color: 'black' }]}>
    Creator to Venue Ratio: {kpiData?.creator_to_venue_ratio?.toFixed(2) || 'N/A'}
  </Text>
</View>




<Text style={{ fontSize: 30, color: 'black', marginBottom: 10, marginTop: 10,  }}>
    {Number(kpiData?.sum_followers || 0).toLocaleString()} Combined Folowers
  </Text>
</View>

      


      {/* Metrics Section */}
      <View style={styles.metricsContainer}>
        <Text style={styles.metric}>Total Users: {totalOnboardedAndNewUsers}</Text>
        <Text style={styles.metric}>Number of Promoters: {promoters}</Text>
        <Text style={styles.metric}>Onboarded Users: {onboardedUsers}</Text>
        <Text style={styles.metric}>New Users: {newUsers}</Text>

      </View>

      {/* Onboarded vs New Users Pie Chart */}
      <Text style={styles.chartTitle}>Onboarded vs New Users</Text>
      <PieChart
        data={pieChartData}
        width={screenWidth - 30}
        height={220}
        chartConfig={{
          backgroundColor: '#ffffff',
          color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
        }}
        accessor="count"
        backgroundColor="transparent"
        paddingLeft="15"
        absolute
      />

      {/* Cumulative Users Line Chart */}
      <Text style={styles.chartTitle}>Cumulative Users Over Time</Text>
      <LineChart
        data={{
          labels: cumulativeLabels,
          datasets: [
            {
              data: cumulativeCounts,
            },
          ],
        }}
        width={screenWidth - 30}
        height={220}
        yAxisLabel=""
        chartConfig={{
          backgroundColor: '#e26a00',
          backgroundGradientFrom: '#fb8c00',
          backgroundGradientTo: '#ffa726',
          decimalPlaces: 0,
          color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
          labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
          style: { borderRadius: 16 },
          propsForDots: {
            r: '6',
            strokeWidth: '2',
            stroke: '#ffa726',
          },
        }}
        bezier
        style={{ marginVertical: 8, borderRadius: 16 }}
      />

      {/* Daily Users Line Chart */}
      <Text style={styles.chartTitle}>New Daily Users</Text>
      <LineChart
        data={{
          labels: dailyLabels,
          datasets: [
            {
              data: dailyCounts,
            },
          ],
        }}
        width={screenWidth - 30}
        height={220}
        yAxisLabel=""
        chartConfig={{
          backgroundColor: '#22a6b3',
          backgroundGradientFrom: '#34e7e4',
          backgroundGradientTo: '#3d3e73',
          decimalPlaces: 0,
          color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
          labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
          style: { borderRadius: 16 },
          propsForDots: {
            r: '6',
            strokeWidth: '2',
            stroke: '#22a6b3',
          },
        }}
        bezier
        style={{ marginVertical: 8, borderRadius: 16 }}
      />

      {/* Collabs Section */}
      <Text style={styles.chartTitle}>Collaborations Overview</Text>
      <View style={styles.collabContainer}>
        <Text style={styles.metric}>Total Collabs: {totalCollabs}</Text>
        <Text style={styles.metric}>Upcoming Collabs: {upcomingCollabs.length}</Text>
        <Text style={styles.metric}>Past Collabs: {pastCollabs.length}</Text>
      </View>

 {/* Render the collabsByBrand as a table */}

{/* Render the collabsByBrand as a table */}
<Text style={styles.chartTitle}>Collaborations by Brand</Text>
<View style={styles.tableContainer}>
  <View style={styles.tableHeader}>
    <Text style={styles.tableHeaderText}>Brand</Text>
    <Text style={styles.tableHeaderText}>Total Collabs</Text>
    <Text style={styles.tableHeaderText}>Upcoming</Text>
    <Text style={styles.tableHeaderText}>Past</Text>
    <Text style={styles.tableHeaderText}>Unique Promoters</Text>
  </View>

  {Object.keys(collabsByBrand).map((brand) => {
  const brandCollabs = upcomingCollabs
  .filter((item) => item.BrandName === brand)
  .map((item) => {
    const today = new Date().setHours(0, 0, 0, 0); // Reset to midnight for consistent calculation
    const collabDate = new Date(item.CreditAvailableStart).setHours(0, 0, 0, 0);
    const daysRemaining = (collabDate - today) / (1000 * 60 * 60 * 24); // Difference in days
    return { ...item, daysRemaining: Math.round(daysRemaining) };
  })
  .sort((a, b) => a.daysRemaining - b.daysRemaining);

      
      const sendPromoterReminder = (promoter, collabID) => {
        axios
          .post("https://collabbayadmin.com/APIControllers/SendPromoterReminder.php", {
            promoter, // Promoter Username
            collabID, // Collaboration ID
          })
          .then(() => {
            // Show success alert
            alert("Success", "Reminder sent successfully!");
          })
          .catch((error) => {
            // Show error alert
            alert("Error", error.response?.data?.message || "Failed to send reminder.");
          });
      };
      
  
    return (
      <View key={brand} style={styles.tableRow}>
        <View style={styles.rowContent}>
          <Text style={styles.tableCell}>{brand}</Text>
          <Text style={styles.tableCell}>{collabsByBrand[brand].totalCollabs}</Text>
          <Text style={styles.tableCell}>{collabsByBrand[brand].upcomingCollabs}</Text>
          <Text style={styles.tableCell}>{collabsByBrand[brand].pastCollabs}</Text>
          <Text style={styles.tableCell}>{collabsByBrand[brand].promoters}</Text>
        </View>

        <FlatList
          data={brandCollabs}
          keyExtractor={(item) => item.CollabID.toString()}
          renderItem={({ item }) => (
            <View
              style={[
                styles.collabRow,
                { backgroundColor: item.daysRemaining < 3 ? "yellow" : "#ebebeb" },
              ]}
            >
              <Text style={[styles.collabText, { fontSize: 9 }]}>
                Collab ID: {item.CollabID}
              </Text>
              <Text
                style={[
                  styles.collabText,
                  { fontSize: 15, fontWeight: "bold" },
                ]}
              >
                @{item.Promoter} on {item.CreditAvailableStart}
              </Text>

              {/* Display Days Remaining */}
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: item.daysRemaining > 0 ? "black" : "black",
                  marginTop: 7,
                  marginBottom: 7,
                  textAlign: "center", 
                }}
              >
                {item.daysRemaining > 0
                  ? `In ${item.daysRemaining} days`
                  : "Today"}
              </Text>

   
              <Text
  style={[
    styles.collabText,
    { 
      fontSize: 10, 
      fontWeight: "bold",
      backgroundColor: item.Status === "Pending" ? "yellow" : item.Status === "Accepted" ? "green" : "transparent",
      color: "black", // Ensures text remains visible
      borderRadius: 10,
      padding: 5,
      width: '20%',
      borderColor: item.Status === "Pending" ? "black" : item.Status === "Accepted" ? "green" : "transparent",
    }
  ]}
>
  {item.Status}
</Text>

              {/* Promoter Reminder Button */}
              {item.Status === "Accepted" && (
  <TouchableOpacity
    style={{
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      backgroundColor: "black",
      borderRadius: 6,
      padding: 5,
      width: "20%",
      marginTop: 10,
      marginLeft: 0,
    }}
    onPress={() => sendPromoterReminder(item.Promoter, item.CollabID)}
  >
    <Ionicons name="mail-outline" size={16} color="white" style={{ marginRight: 5 }} />
    <Text style={{ color: "#fff", fontSize: 12, textAlign: "left" }}>
      Promoter
    </Text>
  </TouchableOpacity>
)}

              {/* Brand Reminder Button */}
              <TouchableOpacity
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  backgroundColor: "black",
                  borderRadius: 6,
                  padding: 5,
                  width: "20%", // Set the width to 20%
                  marginTop: 10,
                  marginLeft: 0, // Align it to the left of the card
                }}
                onPress={() => sendBrandReminder(item.BrandName, item.CollabID)}
              >
                <Ionicons name="mail-outline" size={16} color="white" style={{ marginRight: 5 }} />
                <Text style={{ color: "#fff", fontSize: 12, textAlign: "left" }}>
                  Brand
                </Text>
              </TouchableOpacity>
            </View>
          )}
        />
      </View>
    );
  })}
</View>



      {/* New Section: List of New Users */}
   
{/* New Section: List of New Users */}
<Text style={styles.chartTitle}>New Users</Text>



        {/* Onboard & Assign Public Figure Button */}



        <FlatList
  data={newUserList}
  keyExtractor={(item) => item.UserID.toString()}
  renderItem={({ item }) => (
    <View style={styles.newUserCard}>
      {/* User Details */}
      <Text style={styles.newUserText}>
        <Text style={styles.boldText}>Username:</Text> {item.Username}
      </Text>
      <Text style={styles.newUserText}>
        <Text style={styles.boldText}>Platform Username:</Text> @{item.PlatformUsername}
      </Text>
      <Text style={styles.newUserText}>
        <Text style={styles.boldText}>Email:</Text> {item.UserEmail}
      </Text>
      <Text style={styles.newUserText}>
        <Text style={styles.boldText}>Profile Created: </Text> {item.Created_At}
      </Text>

      {/* New Text Inputs */}
      <TextInput
        style={styles.textInput}
        placeholder="Platform Followers"
        value={inputData[item.UserID]?.PlatformFollowers || ''}
        onChangeText={(text) =>
          setInputData((prev) => ({
            ...prev,
            [item.UserID]: { ...prev[item.UserID], PlatformFollowers: text },
          }))
        }
      />
      <TextInput
        style={styles.textInput}
        placeholder="Audience Location"
        value={inputData[item.UserID]?.AudienceLocation || ''}
        onChangeText={(text) =>
          setInputData((prev) => ({
            ...prev,
            [item.UserID]: { ...prev[item.UserID], AudienceLocation: text },
          }))
        }
      />
      <TextInput
        style={styles.textInput}
        placeholder="Bots/Fakes"
        value={inputData[item.UserID]?.BotsFakes || ''}
        onChangeText={(text) =>
          setInputData((prev) => ({
            ...prev,
            [item.UserID]: { ...prev[item.UserID], BotsFakes: text },
          }))
        }
      />

      {/* Instagram Link */}
      <Text
        style={styles.instagramLink}
        onPress={() => {
          const url = `https://instagram.com/${item.PlatformUsername}`;
          Linking.openURL(url);
        }}
      >
        View Instagram Profile
      </Text>

      {/* Onboard & Assign Public Figure Button */}
      <Button
        title="Onboard & Assign Public Figure"
        onPress={() =>
          handleOnboardAndAssignPublicFigure(
            item.Username,
            inputData[item.UserID]?.PlatformFollowers,
            inputData[item.UserID]?.AudienceLocation,
            inputData[item.UserID]?.BotsFakes
          )
        }
        color="black"
      />
    </View>
  )}
/>


<Text style={styles.chartTitle}>User Directory</Text>
<FlatList
  data={userList}
  keyExtractor={(item) => item.Username}  // Use Username as the key
  renderItem={({ item }) => (
    <View style={styles.userRow}>
      {/* Profile Image */}
      <Image source={{ uri: item.ProfileImage }} style={styles.profileImage} />
      
      {/* User Details */}
      <View style={styles.userDetails}>
        <Text style={styles.userText}>Username: {item.Username}</Text>
        <Text style={styles.userText}>Platform Followers: {item.PlatformFollowers}</Text>
        <Text style={styles.userText}>Status: {item.Status}</Text>
        <Text style={styles.userText}>@{item.PlatformUsername}</Text>
        <Text style={styles.userText}>{item.UserEmail}</Text>
      </View>
    </View>
  )}
/>


    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: { flex: 1, padding: 20, backgroundColor: '#f4f4f9' },
  title: { fontSize: 24, fontWeight: 'bold', marginBottom: 10 },
  metricsContainer: { marginBottom: 20 },
  metric: { fontSize: 16, marginVertical: 4 },
  chartTitle: { fontSize: 20, fontWeight: 'bold', marginVertical: 10 },
  collabContainer: { marginBottom: 20 },
  brandContainer: { marginBottom: 20 },
  brandName: { fontSize: 18, fontWeight: 'bold' },
  brandMetric: { fontSize: 16, marginVertical: 4 },
  upcomingSection: { marginTop: 10, paddingLeft: 10 },
  upcomingTitle: { fontSize: 16, fontWeight: 'bold' },
  upcomingItem: { marginBottom: 10 },
  upcomingText: { fontSize: 14 },
  userRow: { 
    flexDirection: 'row',  // Row layout for image and text
    marginBottom: 20,
    padding: 10,
    backgroundColor: '#f8f9fa',
    borderRadius: 8,
    alignItems: 'center',  // Center image and text
  },
  profileImage: {
    width: 40,  // Small image size
    height: 40,
    borderRadius: 20,  // Rounded image
    marginRight: 10,  // Space between image and text
  },
  userDetails: {
    flexDirection: 'column',
  },
  userText: {
    fontSize: 16,
    marginVertical: 2,
  },
  tableContainer: {
    marginVertical: 10,
    borderWidth: 1,
    borderColor: '#ddd',
    borderRadius: 8,
    overflow: 'hidden',
  },
  tableHeader: {
    flexDirection: 'row',
    backgroundColor: '#f4f4f9',
    padding: 10,
  },
  tableHeaderText: {
    flex: 1,
    fontWeight: 'bold',
    fontSize: 16,
    textAlign: 'center',
  },
  tableRow: {
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    paddingVertical: 10,
  },
  rowContent: {
    flexDirection: 'row',
    padding: 10,
    backgroundColor: '#ffffff',
  },
  tableCell: {
    flex: 1,
    fontSize: 14,
    textAlign: 'center',
  },
  collabRow: {
    padding: 10,
    marginHorizontal: 10,
    marginBottom: 5,
    borderRadius: 6,
  },
  collabText: {
    fontSize: 14,
  },
  newUserCard: {
    marginBottom: 20,
    padding: 15,
    backgroundColor: '#ffffff',
    borderRadius: 8,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
    borderWidth: 1,
    borderColor: '#ddd',
  },
  newUserText: {
    fontSize: 16,
    marginVertical: 4,
    color: '#333',
  },
  boldText: {
    fontWeight: 'bold',
  },
  instagramLink: {
    color: '#007bff',
    fontSize: 16,
    marginVertical: 5,
    textDecorationLine: 'underline',
  },
  metricsContainer: {
    marginBottom: 20,
  },
  metric: {
    fontSize: 16,
    marginVertical: 4,
  },
  listItem: {
    fontSize: 14,
    marginLeft: 10,
  },
  textInput: {
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 8,
    padding: 10,
    marginBottom: 10,
    backgroundColor: "#f9f9f9",
  },
  
  
  
});