import React, { Component } from 'react';
import { View, TextInput, TouchableOpacity, Text, StyleSheet, Linking } from 'react-native';
import Feather from 'react-native-vector-icons/Feather';

export default class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      confirmPw: '',
      username: '',
      businessName: '',
      businessAddress: '',
      businessPhoneContact: '',
      userSource: '',
      secureTextEntry: true,
      confirmSecureTextEntry: true,
    };
  }

  InsertRecord = () => {
    const {
      email,
      password,
      confirmPw,
      username,
      businessName,
      businessAddress,
      businessPhoneContact,
      userSource,
    } = this.state;
    const checkEmail = RegExp(/^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,3})$/i);

    if (
      email.length === 0 ||
      password.length === 0 ||
      confirmPw.length === 0 ||
      username.length === 0 ||
      businessName.length === 0 ||
      businessAddress.length === 0 ||
      businessPhoneContact.length === 0 ||
      userSource.length === 0
    ) {
      alert('Required Field Is Missing');
    } else if (!checkEmail.test(email)) {
      alert('Invalid email!');
    } else if (password.length < 8) {
      alert('Minimum 8 characters required');
    } else if (!/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password)) {
      alert('Use at least 1 special character');
    } else if (/\s/.test(password)) {
      alert("Don't include space in password");
    } else if (password !== confirmPw) {
      alert('Password does not match');
    } else {
      const InsertAPIURL = 'https://collabbayadmin.com/SignUp.php';

      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };

      const Data = {
        Email: email,
        Password: password,
        Username: username,
        BusinessName: businessName,
        BusinessAddress: businessAddress,
        BusinessPhoneContact: businessPhoneContact,
        UserSource: userSource,
      };

      fetch(InsertAPIURL, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(Data),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response && response.Message) {
            if (response.Status === 'Success') {
              window.location.href = 'https://collabbayadmin.com/'; // Redirect to the specified URL upon successful registration
          }
          } else {
            alert('Unexpected response format');
          }
        })
        .catch((error) => {
          alert('Error Occurred: ' + error.message);
        });
    }
  };

  updateSecureTextEntry = () => {
    this.setState({
      secureTextEntry: !this.state.secureTextEntry,
    });
  };

  updateConfirmSecureTextEntry = () => {
    this.setState({
      confirmSecureTextEntry: !this.state.confirmSecureTextEntry,
    });
  };

  render() {
    return (
     
      <View style={[styles.container, { width: '100%', maxWidth: 600, alignSelf: 'center', backgroundColor: '#fff', flex: 1 }]}>

     <TouchableOpacity style={styles.backButton} onPress={() => this.props.navigation.goBack()}>
          <Feather name="arrow-left" size={24} color="#000" />
        </TouchableOpacity>

        <Text style={styles.header}>Sign Up Now</Text>
        <Text style={styles.subText}>Your collabs are waiting</Text>

        <TextInput
          placeholder="Create Username (Your Brand Name)"
          placeholderTextColor="#999"
          style={styles.textInput}
          onChangeText={(username) => this.setState({ username })}
        />
        <TextInput
          placeholder="Enter Business Email"
          placeholderTextColor="#999"
          style={styles.textInput}
          onChangeText={(email) => this.setState({ email })}
        />

        <Text style={styles.subPasswordText}>Minimum 8 characters | 1 Special character | No spaces</Text>
        <View style={styles.passwordContainer}>
          <TextInput
            placeholder="Enter Password"
            placeholderTextColor="#999"
            secureTextEntry={this.state.secureTextEntry}
            style={styles.textInput}
            onChangeText={(password) => this.setState({ password })}
          />
          <TouchableOpacity style={styles.icon} onPress={this.updateSecureTextEntry}>
            {this.state.secureTextEntry ? (
              <Feather name="eye-off" color="#999" size={20} />
            ) : (
              <Feather name="eye" color="#333" size={20} />
            )}
          </TouchableOpacity>
        </View>

        <View style={styles.passwordContainer}>
          <TextInput
            placeholder="Confirm Password"
            placeholderTextColor="#999"
            secureTextEntry={this.state.confirmSecureTextEntry}
            style={styles.textInput}
            onChangeText={(confirmPw) => this.setState({ confirmPw })}
          />
          <TouchableOpacity style={styles.icon} onPress={this.updateConfirmSecureTextEntry}>
            {this.state.confirmSecureTextEntry ? (
              <Feather name="eye-off" color="#999" size={20} />
            ) : (
              <Feather name="eye" color="#333" size={20} />
            )}
          </TouchableOpacity>
        </View>

        <Text style={styles.tellUsMoreHeader}>Tell Us More</Text>

        <TextInput
          placeholder="Your Business Name LTD"
          placeholderTextColor="#999"
          style={styles.textInput}
          onChangeText={(businessName) => this.setState({ businessName })}
        />
        <TextInput
          placeholder="Business Address"
          placeholderTextColor="#999"
          style={styles.textInput}
          onChangeText={(businessAddress) => this.setState({ businessAddress })}
        />
        <TextInput
          placeholder="Contact Number"
          placeholderTextColor="#999"
          style={styles.textInput}
          onChangeText={(businessPhoneContact) => this.setState({ businessPhoneContact })}
        />
        <TextInput
          placeholder="How did you hear about us?"
          placeholderTextColor="#999"
          style={styles.textInput}
          onChangeText={(userSource) => this.setState({ userSource })}
        />

        <TouchableOpacity style={styles.registerButton} onPress={this.InsertRecord}>
          <Text style={styles.buttonText}>Create Account</Text>
        </TouchableOpacity>

        <TouchableOpacity onPress={() => Linking.openURL('https://collabbay.com/PrivacyPolicy.html')}>
          <Text style={styles.linkText}> By signing up, you agree to our Privacy Policy & Terms of Use.</Text>
        </TouchableOpacity>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    paddingTop: 20,
  },
  backButton: {
    position: 'absolute',
    top: 40,
    left: 20,
  },
  header: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 8,
    color: 'black',
  },
  subText: {
    fontSize: 14,
    color: '#999',
    marginBottom: 20,
  },
  tellUsMoreHeader: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
    color: 'black',
  },
  textInput: {
    width: '80%',
    height: 50,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    marginBottom: 20,
    fontSize: 16,
    paddingHorizontal: 10,
    color: 'black',
  },
  passwordContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  icon: {
    position: 'absolute',
    right: 10,
  },
  registerButton: {
    backgroundColor: '#FDF05D',
    width: '80%',
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    marginBottom: 20,
  },
  buttonText: {
    color: 'black',
    fontSize: 18,
  },
  linkText: {
    fontSize: 9,
    color: 'black',
    textDecorationLine: 'underline',
  },
  subPasswordText: {
    fontSize: 12,
    color: '#999',
    padding: 10,
    marginTop: 5,
    marginBottom: 5,
  },
});
