// React Native JS for fetching and updating listing data
import React, { useState, useEffect } from 'react';
import {
  StyleSheet,
  Modal,
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Alert,
  ScrollView,
  SafeAreaView,
  Linking,
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';

const followerOptions = [
  { label: 'No min.', value: 0 },
  { label: '5k', value: 5000 },
  { label: '10k', value: 10000 },
  { label: '15k', value: 15000 },
  { label: '20k', value: 20000 },
  { label: '25k', value: 25000 },
  { label: '30k', value: 30000 },
  { label: '35k', value: 35000 },
  { label: '40k', value: 40000 },
  { label: '45k', value: 45000 },
  { label: '50k', value: 50000 },
  { label: '60k', value: 60000 },
  { label: '70k', value: 70000 },
  { label: '80k', value: 80000 },
  { label: '90k', value: 90000 },
  { label: '100k', value: 100000 },
  { label: '150k', value: 150000 },
  { label: '200k', value: 200000 },
  { label: '250k', value: 250000 },
  { label: '300k', value: 300000 },
  { label: '350k', value: 350000 },
  { label: '400k', value: 400000 },
  { label: '450k', value: 450000 },
  { label: '500k', value: 500000 },
];

const timeOptions = [
  '6:00 AM', '6:30 AM',
  '7:00 AM', '7:30 AM',
  '8:00 AM', '8:30 AM',
  '9:00 AM', '9:30 AM',
  '10:00 AM', '10:30 AM',
  '11:00 AM', '11:30 AM',
  '12:00 PM', '12:30 PM',
  '1:00 PM', '1:30 PM',
  '2:00 PM', '2:30 PM',
  '3:00 PM', '3:30 PM',
  '4:00 PM', '4:30 PM',
  '5:00 PM', '5:30 PM',
  '6:00 PM', '6:30 PM',
  '7:00 PM', '7:30 PM',
  '8:00 PM', '8:30 PM',
  '9:00 PM', '9:30 PM',
  '10:00 PM', '10:30 PM',
  '11:00 PM', '11:30 PM',
  '12:00 AM', '12:30 AM',
  '1:00 AM', '1:30 AM',
];

export default function EditListing({ route, navigation }) {
  const { ListingID } = route.params; // ListingID passed via route params

  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isTimeModalVisible, setIsTimeModalVisible] = useState(false);
  const [selectedDay, setSelectedDay] = useState('');
  const [selectedTimeType, setSelectedTimeType] = useState('');
  const [minFollowersRequired, setMinFollowersRequired] = useState('No min.');

  // Fetch the listing data based on ListingID
 // Fetch the listing data based on ListingID
// Fetch the listing data based on ListingID
useEffect(() => {
  const fetchListingData = async () => {
    try {
      const response = await fetch(
        `https://collabbayadmin.com/APIControllers/GetListingByID.php?ListingID=${ListingID}`
      );
      const result = await response.json();

      if (response.ok) {
        // Split ContentBrief into individual fields
        const contentBriefs = (result.ContentBrief || '').split(',').map((item) => item.trim());

        // Use PreferredAvailability as is
        const availability = result.PreferredAvailability || '';

        setFormData({
          ...result,
          ContentBrief1: contentBriefs[0] || '',
          ContentBrief2: contentBriefs[1] || '',
          ContentBrief3: contentBriefs[2] || '',
          PreferredAvailability: availability, // Store availability as raw data
        });
      } else {
        Alert.alert('Error', result.error || 'Failed to fetch listing data.');
      }
    } catch (error) {
      Alert.alert('Error', 'An error occurred while fetching the listing data.');
    } finally {
      setLoading(false);
    }
  };

  fetchListingData();
}, [ListingID]);

  

  const handleInputChange = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  const toggleDropdown = () => setDropdownVisible(!isDropdownVisible);

  const selectMinFollowersOption = (option) => {
    setMinFollowersRequired(option.label);
    setDropdownVisible(false);
  };

  const handleTimeSelection = (day, type, time) => {
    setFormData((prev) => ({
      ...prev,
      PreferredAvailability: {
        ...prev.PreferredAvailability,
        [day]: {
          ...prev.PreferredAvailability?.[day],
          [type]: time, // Store the selected time
        },
      },
    }));
    setIsTimeModalVisible(false); // Close modal after selection
  };
  
  

  const openTimeModal = (day, type) => {
    setSelectedDay(day);
    setSelectedTimeType(type);
    setIsTimeModalVisible(true);
  };

  const handleSubmit = async () => {
    if (!termsAccepted) {
      Alert.alert('Terms of Use', 'You must agree to the terms of use before submitting.');
      return;
    }
  
    if (!formData.Location) {
      Alert.alert('Error', 'Location is required.');
      return;
    }
  
    if (!formData.ListingDescription) {
      Alert.alert('Error', 'Listing description is required.');
      return;
    }
  
    if (!formData.AdHeader) {
      Alert.alert('Error', 'Ad header is required.');
      return;
    }
  
    if (!formData.BrandURL) {
      Alert.alert('Error', 'Brand URL is required.');
      return;
    }
  
    // Format ContentBrief from the separate input fields
    const contentBrief = [
      formData.ContentBrief1 || '',
      formData.ContentBrief2 || '',
      formData.ContentBrief3 || '',
    ]
      .filter((brief) => brief.trim() !== '')
      .join(', ');
  
    if (!contentBrief) {
      Alert.alert('Error', 'At least one content brief is required.');
      return;
    }
  
    // Format availability into a string
    const formattedAvailability = Object.entries(formData.PreferredAvailability || {})
      .filter(([day, times]) => times.open && times.close)
      .map(([day, times]) => `${day}: ${times.open} - ${times.close}`)
      .join(', ');
  
    if (!formattedAvailability) {
      Alert.alert('Error', 'Please provide availability with at least one open and close time.');
      return;
    }
  
    const payload = {
      ...formData,
      ListingID, // Ensure ListingID is included
      MinFollowersRequired: minFollowersRequired || 'No min.',
      PreferredAvailability: formattedAvailability,
      ContentBrief: contentBrief,
      NonCreditOffer: formData.NonCreditOffer || '',
      TurnAroundDays: formData.TurnAroundDays || null,
    };
  
    try {
      console.log('Submitting payload:', payload); // Debug
  
      const response = await fetch('https://collabbayadmin.com/APIControllers/UpdateListing.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      const result = await response.json();
      console.log('API Response:', result); // Debug
  
      if (response.ok && result.message === 'Data updated successfully') {
        Alert.alert('Success', 'Listing updated successfully.');
        navigation.goBack();
      } else {
        Alert.alert('Error', result.error || 'Failed to update listing.');
      }
    } catch (error) {
      console.error('API Error:', error); // Debug
      Alert.alert('Error', 'An error occurred while updating the listing.');
    }
  };
  

  if (loading) {
    return (
      <View style={styles.loadingContainer}>
        <Text>Loading...</Text>
      </View>
    );
  }

  return (
    <SafeAreaView style={[styles.container, { width: '100%', maxWidth: 600, alignSelf: 'center', backgroundColor: '#fff', flex: 1 }]}>


      <ScrollView contentContainerStyle={styles.formContainer}>
        <TouchableOpacity style={styles.backButton} onPress={() => navigation.goBack()}>
          <Ionicons name="arrow-back" size={24} color="black" />
        </TouchableOpacity>

        <Text style={styles.header}>Edit Listing</Text>

        <Text style={styles.sectionHeader}>General Information</Text>

        <View style={styles.inputContainer}>
          <Ionicons name="location-outline" size={24} color="black" style={styles.icon} />
          <TextInput
            style={styles.input}
            placeholder="Location"
            placeholderTextColor="gray"
            value={formData.Location || ''}
            onChangeText={(value) => handleInputChange('Location', value)}
          />
        </View>

        <View style={styles.inputContainer}>
          <Ionicons name="text-outline" size={24} color="black" style={styles.icon} />
          <TextInput
            style={[styles.input, { height: 110 }]}
            placeholder="Listing Description"
            placeholderTextColor="gray"
            multiline
            value={formData.ListingDescription || ''}
            onChangeText={(value) => handleInputChange('ListingDescription', value)}
          />
        </View>

        <Text style={styles.sectionHeader}>Offer Details</Text>

        <View style={styles.inputContainer}>
          <Ionicons name="newspaper-outline" size={24} color="black" style={styles.icon} />
          <TextInput
            style={styles.input}
            placeholder="Ad Header"
            placeholderTextColor="gray"
            value={formData.AdHeader || ''}
            onChangeText={(value) => handleInputChange('AdHeader', value)}
          />
        </View>

        <View style={styles.inputContainer}>
          <Ionicons name="link-outline" size={24} color="black" style={styles.icon} />
          <TextInput
            style={styles.input}
            placeholder="Brand URL"
            placeholderTextColor="gray"
            value={formData.BrandURL || ''}
            onChangeText={(value) => handleInputChange('BrandURL', value)}
          />
        </View>

        <View style={styles.inputContainer}>
        <Ionicons name="pricetag-outline" size={24} color="black" style={styles.icon} />
          <TextInput
            style={styles.input}
            placeholder="Credits (0-10000)"
            placeholderTextColor="gray"
            keyboardType="numeric"
            value={formData.Credits?.toString() || ''}
            onChangeText={(value) => handleInputChange('Credits', value.replace(/[^0-9]/g, ''))}
          />
        </View>

      

        <View>
  <Text style={styles.dropdownLabel}>Minimum Followers</Text>
  <TouchableOpacity style={styles.dropdownButton} onPress={toggleDropdown}>
    <Text style={styles.dropdownButtonText}>{minFollowersRequired}</Text>
    <Ionicons
      name={isDropdownVisible ? 'chevron-up-outline' : 'chevron-down-outline'}
      size={20}
      color="black"
    />
  </TouchableOpacity>
  {isDropdownVisible && (
    <ScrollView style={styles.dropdownList}>
      {followerOptions.map((option) => (
        <TouchableOpacity
          key={option.value}
          style={styles.dropdownItem}
          onPress={() => selectMinFollowersOption(option)}
        >
          <Text style={styles.dropdownItemText}>{option.label}</Text>
        </TouchableOpacity>
      ))}
    </ScrollView>
  )}
</View>


        <Text style={styles.sectionHeader}>Content Brief</Text>
<View style={styles.inputContainer}>
  <Ionicons name="bulb-outline" size={24} color="black" style={styles.icon} />
  <TextInput
    style={styles.input}
    placeholder="Brief Point 1 (e.g., 'Mention our new menu')"
    placeholderTextColor="gray"
    value={formData.ContentBrief1 || ''}
    onChangeText={(value) => handleInputChange('ContentBrief1', value)}
  />
</View>
<View style={styles.inputContainer}>
  <Ionicons name="bulb-outline" size={24} color="black" style={styles.icon} />
  <TextInput
    style={styles.input}
    placeholder="Brief Point 2 (optional)"
    placeholderTextColor="gray"
    value={formData.ContentBrief2 || ''}
    onChangeText={(value) => handleInputChange('ContentBrief2', value)}
  />
</View>
<View style={styles.inputContainer}>
  <Ionicons name="bulb-outline" size={24} color="black" style={styles.icon} />
  <TextInput
    style={styles.input}
    placeholder="Brief Point 3 (optional)"
    placeholderTextColor="gray"
    value={formData.ContentBrief3 || ''}
    onChangeText={(value) => handleInputChange('ContentBrief3', value)}
  />
</View>



<Text style={styles.sectionHeader}>CTA Link Details</Text>
<View style={styles.inputContainer}>
    <Ionicons name="newspaper-outline" size={24} color="black" style={styles.icon} />
    <TextInput
        style={styles.input}
        placeholder="Ad Header (e.g., 'Try our new menu!')"
        placeholderTextColor="gray"
        value={formData.AdHeader || ''}
        onChangeText={(value) => handleInputChange('AdHeader', value)}
    />
</View>
<View style={styles.inputContainer}>
    <Ionicons name="link-outline" size={24} color="black" style={styles.icon} />
    <TextInput
        style={styles.input}
        placeholder="Brand URL (e.g., 'https://example.com')"
        placeholderTextColor="gray"
        value={formData.BrandURL || ''}
        onChangeText={(value) => handleInputChange('BrandURL', value)}
    />
</View>



<Text style={styles.sectionHeader}>Availability (Required)</Text>

{['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
  <View key={day} style={styles.availabilityRow}>
    <Text style={styles.availabilityDay}>{day}</Text>
    <TouchableOpacity
      style={styles.timeButton}
      onPress={() => openTimeModal(day, 'open')}
    >
      <Text>{formData.PreferredAvailability?.[day]?.open || 'Open'}</Text>
    </TouchableOpacity>
    <TouchableOpacity
      style={styles.timeButton}
      onPress={() => openTimeModal(day, 'close')}
    >
   <Text>{formData.PreferredAvailability?.[day]?.close || 'Close'}</Text>

    </TouchableOpacity>
  </View>
))}



        <Modal
          visible={isTimeModalVisible}
          transparent={true}
          animationType="slide"
          onRequestClose={() => setIsTimeModalVisible(false)}
        >
          <View style={styles.modalContainer}>
            <View style={styles.modalContent}>
              <Text style={styles.modalTitle}>Select Time</Text>
              <ScrollView style={styles.timeList}>
                {timeOptions.map((time, index) => (
                  <TouchableOpacity
                    key={index}
                    style={styles.timeItem}
                    onPress={() => handleTimeSelection(selectedDay, selectedTimeType, time)}
                  >
                    <Text style={styles.timeText}>{time}</Text>
                  </TouchableOpacity>
                ))}
              </ScrollView>
              <TouchableOpacity
                style={styles.modalCloseButton}
                onPress={() => setIsTimeModalVisible(false)}
              >
                <Text style={styles.modalCloseButtonText}>Cancel</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>

        <View style={styles.termsContainer}>
          <TouchableOpacity onPress={() => setTermsAccepted(!termsAccepted)} style={styles.checkbox}>
            <Ionicons
              name={termsAccepted ? 'checkbox-outline' : 'square-outline'}
              size={24}
              color="black"
            />
          </TouchableOpacity>
          <Text style={styles.termsText}>
            By saving this offer I agree with{' '}
            <Text style={styles.linkText} onPress={() => Linking.openURL('https://collabbay.com/PrivacyPolicy.html')}>
              Collabbay terms of use
            </Text>.
          </Text>
        </View>

        <TouchableOpacity style={styles.button} onPress={handleSubmit}>
          <Text style={styles.buttonText}>Save Changes</Text>
        </TouchableOpacity>
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  formContainer: {
    padding: 20,
  },
  backButton: {
    position: 'absolute',
    top: 20,
    left: 20,
    zIndex: 1,
  },
  header: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 20,
    marginTop: 10,
    color: 'black',
  },
  sectionHeader: {
    fontSize: 18,
    fontWeight: 'bold',
    marginVertical: 10,
    color: 'black',
  },
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#f5f5f5',
    borderRadius: 10,
    paddingHorizontal: 15,
    paddingVertical: 10,
    marginBottom: 15,
  },
  icon: {
    marginRight: 10,
  },
  input: {
    flex: 1,
    height: 40,
    backgroundColor: '#f5f5f5',
    borderRadius: 10,
    paddingHorizontal: 10,
    color: 'black',
  },
  dropdownLabel: {
    fontSize: 14,
    marginBottom: 5,
    color: 'black',
  },
  dropdownButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#f5f5f5',
    padding: 10,
    borderRadius: 10,
    marginBottom: 10,
  },
  dropdownButtonText: {
    color: 'black',
  },
  dropdownList: {
    backgroundColor: '#ffffff',
    borderRadius: 10,
    maxHeight: 200,
    marginTop: 5,
    borderWidth: 1,
    borderColor: '#ddd',
  },
  dropdownItem: {
    padding: 10,
  },
  dropdownItemText: {
    color: 'black',
  },
  availabilityRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  availabilityDay: {
    fontSize: 14,
    flex: 1,
    color: 'black',
  },
  timeButton: {
    flex: 1,
    backgroundColor: '#f5f5f5',
    borderWidth: 1,
    borderColor: '#ddd',
    borderRadius: 5,
    paddingVertical: 10,
    alignItems: 'center',
    marginHorizontal: 5,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 20,
    width: '80%',
    alignItems: 'center',
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  timeList: {
    width: '100%',
    maxHeight: 300,
  },
  timeItem: {
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    alignItems: 'center',
  },
  timeText: {
    fontSize: 16,
    color: '#000',
  },
  modalCloseButton: {
    marginTop: 20,
    backgroundColor: '#f5f5f5',
    padding: 10,
    borderRadius: 5,
    alignItems: 'center',
    width: '50%',
  },
  modalCloseButtonText: {
    color: '#000',
    textAlign: 'center',
  },
  termsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 15,
  },
  checkbox: {
    marginRight: 10,
  },
  termsText: {
    fontSize: 12,
    color: 'black',
  },
  linkText: {
    color: 'blue',
    textDecorationLine: 'underline',
  },
  button: {
    backgroundColor: '#FDF05D',
    borderRadius: 20,
    paddingVertical: 15,
    alignItems: 'center',
    marginTop: 20,
  },
  buttonText: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: 16,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
